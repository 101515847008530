import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

//Imagens
import logoPrincipal from "../../img/logo_extenso.png";

const Nav = () => {
  const location = useLocation();

  const navAnim = {
    hidden: { y: -200 },
    show: { y: 0, transition: { duration: 1, ease: "easeOut" } },
  };

  return (
    <>
      <StyledNavbar></StyledNavbar>
      <StyledNav>
        <motion.div variants={navAnim} initial="hidden" animate="show">
          <a href="/">
            <img src={logoPrincipal} alt="Logo"></img>
          </a>
          <a className={`link ${location.pathname === "/" ? "selected" : ""}`} href="/">
            Início
          </a>
          <a className={`link ${location.pathname === "/tecnologias" ? "selected" : ""}`} href="/tecnologias">
            Tecnologias
          </a>
          {/* <a className={`link ${location.pathname === "/nosso-time" ? "selected" : ""}`} href="/nosso-time">
                                    Nosso time
                              </a> */}
          <a className={`link ${location.pathname === "/trabalhe-conosco" ? "selected" : ""}`} href="/trabalhe-conosco">
            Trabalhe conosco
          </a>
        </motion.div>
      </StyledNav>
    </>
  );
};

const StyledNavbar = styled.div`
  min-width: 100vw;
  min-height: 52px;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  @media screen and (max-width: 1400px) {
    min-height: 48px;
  }
`;

const StyledNav = styled(motion.nav)`
  z-index: 100;
  width: 60vw;
  max-height: 52px;
  max-width: 1000px;
  font-size: 1rem;
  font-family: "Kodchasan-Bold", sans-serif;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  .logo {
    z-index: 2;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 52px;
    img {
      transition: all 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
    }
    .link {
      text-decoration: none;
      color: white;
      padding: 10px;
      transition: all 0.2s ease;
    }
    .link:hover {
      color: #397ee4 !important;
    }
    .selected {
      color: #397ee4;
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 0.9rem;
    max-height: 48px;
  }
`;

export default Nav;
