import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Suspense, lazy, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useMediaQuery } from "react-responsive";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

//Fonts
import KodchasanBold from "./fonts/Kodchasan-Bold.ttf";
import KodchasanMedium from "./fonts/Kodchasan-Medium.ttf";
import LexendBold from "./fonts/Lexend-Bold.ttf";
import LexendMedium from "./fonts/Lexend-Medium.ttf";
import PoppinsRegular from "./fonts/Poppins-Regular.ttf";
import PoppinsSemiBold from "./fonts/Poppins-SemiBold.ttf";

//Components
import Loading from "./components/Loading";
import Nav from "./components/nav/Nav";
import NavMobile from "./components/nav/NavMobile";

//Pages
const Inicial = lazy(() => import("./pages/Inicial"));
const NossoTime = lazy(() => import("./pages/NossoTime"));
const NossoTimeDetalhes = lazy(() => import("./pages/NossoTimeDetalhes"));
const TrabalheConosco = lazy(() => import("./pages/TrabalheConosco"));
const Tecnologias = lazy(() => import("./pages/Tecnologias"));
const Convite = lazy(() => import("./pages/convite/Convite"));

const App = () => {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.config({ autoRefreshEvents: "DOMContentLoaded,load" });

  const isSmallScreen = useMediaQuery({ query: "(min-width:0px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width:568px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width:1250px)" });
  const isExtraLargeScreen = useMediaQuery({ query: "(min-width:1921px)" });

  const [showNav, setShowNav] = useState(true);

  return (
    <div className="App">
      <GlobalStyle />
      <CookieConsent
        buttonText="Eu Entendo"
        style={{
          backgroundColor: "rgba(0,0,0,0.6)",
          WebkitBackdropFilter: "saturate(180%) blur(20px)",
          backdropFilter: "saturate(180%) blur(20px)",
          justifyContent: "center",
        }}
        buttonStyle={{
          backgroundColor: "transparent",
          border: "1px solid white",
          color: "white",
          fontSize: "1.05rem",
          fontFamily: "Poppins-Regular",
          borderRadius: "14px",
          alignSelf: "center",
        }}
        contentStyle={{
          color: "white",
          fontSize: "1.05rem",
          fontFamily: "Poppins-Regular",
          padding: "0.5rem",
          textAlign: `${isLargeScreen ? "start" : "center"}`,
        }}
      >
        Ao clicar em “Eu Entendo”, você concorda com o armazenamento de cookies neste dispositivo, para melhora da navegação e análise de uso do
        site!
      </CookieConsent>
      <Router>
        <Suspense fallback={<Loading />}>
          {showNav && (isLargeScreen ? <Nav /> : <NavMobile />)}
          <Switch>
            <Route exact path="/">
              <Inicial
                isSmallScreen={isSmallScreen}
                isMediumScreen={isMediumScreen}
                isLargeScreen={isLargeScreen}
                isExtraLargeScreen={isExtraLargeScreen}
              />
            </Route>
            <Route path={"/nosso-time/:id"}>
              <NossoTimeDetalhes
                isSmallScreen={isSmallScreen}
                isMediumScreen={isMediumScreen}
                isLargeScreen={isLargeScreen}
                isExtraLargeScreen={isExtraLargeScreen}
              />
            </Route>
            <Route path="/nosso-time">
              <NossoTime
                isSmallScreen={isSmallScreen}
                isMediumScreen={isMediumScreen}
                isLargeScreen={isLargeScreen}
                isExtraLargeScreen={isExtraLargeScreen}
              />
            </Route>
            <Route path="/trabalhe-conosco">
              <TrabalheConosco
                isSmallScreen={isSmallScreen}
                isMediumScreen={isMediumScreen}
                isLargeScreen={isLargeScreen}
                isExtraLargeScreen={isExtraLargeScreen}
              />
            </Route>
            <Route path="/tecnologias">
              <Tecnologias
                isSmallScreen={isSmallScreen}
                isMediumScreen={isMediumScreen}
                isLargeScreen={isLargeScreen}
                isExtraLargeScreen={isExtraLargeScreen}
              />
            </Route>
            <Route path="/convite/:id">
              <Convite isMediumScreen={isMediumScreen} setShowNav={setShowNav} />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Kodchasan-Bold';
    src: url(${KodchasanBold}) format('truetype');
  }

  @font-face {
    font-family: 'Kodchasan-Medium';
    src: url(${KodchasanMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Lexend-Bold';
    src: url(${LexendBold}) format('truetype');
  }

  @font-face {
    font-family: 'Lexend-Medium';
    src: url(${LexendMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-Semibold';
    src: url(${PoppinsSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-Regular';
    src: url(${PoppinsRegular}) format('truetype');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &:focus-visible {
      outline: 3px solid blue;
    }
  }

  html {
    scrollbar-color:#686767 #000;
    scrollbar-width: thin !important;
    font-size: 90%;
  }

  @media screen and (max-width: 1400px) {
    html {
      font-size: 90%;
    }
  }

  html::-webkit-scrollbar {
    width: 8px;
  }

  html::-webkit-scrollbar-track {
      background: #000;
  }

  html::-webkit-scrollbar-thumb {
    background-image: linear-gradient(#686767, #686767);
    border-radius: 10px;
  }

  html::-webkit-scrollbar-thumb:hover {
    background-color: #000;
    border-radius: 10px;
  }

  html::-webkit-scrollbar-thumb:active {
    background-color: #000;
    opacity: .9;
    border-radius: 10px;
  }

  body {
    overflow-x: hidden;
  }
`;
