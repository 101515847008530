import { useEffect } from "react";

// Bibliotecas
import styled from "styled-components";
import gsap from "gsap";

const Loading = () => {
  useEffect(() => {
    const tl = gsap.timeline({
      ease: "none",
      repeat: -1,
    });

    tl.to(".loading-line-1", { scale: 1.5, duration: 0.4 });

    tl.to(".loading-line-1", { scale: 1, duration: 0.4 });

    tl.to(".loading-line-2", { scale: 1.5, duration: 0.4 }, "-=0.4");

    tl.to(".loading-line-2", { scale: 1, duration: 0.4 });

    tl.to(".loading-line-3", { scale: 1.5, duration: 0.4 }, "-=0.4");

    tl.to(".loading-line-3", { scale: 1, duration: 0.4 });
  }, []);

  return (
    <StyledSection>
      <div className="loading-line-1"></div>
      <div className="loading-line-2"></div>
      <div className="loading-line-3"></div>
    </StyledSection>
  );
};

export default Loading;

const StyledSection = styled.section`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    border-radius: 10px;
    display: inline-block;
    transform-origin: center center;
    margin: 0 4px;
    width: 4px;
    height: 50px;

    &:nth-child(1) {
      background: #06ffe7;
    }

    &:nth-child(2) {
      background: #397ee3;
    }

    &:nth-child(3) {
      background: #270c72;
    }
  }
`;
