import { motion } from "framer-motion";
import gsap from "gsap";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

//Imagens
import logoPrincipal from "../../img/logo_extenso.png";

// Social Medias
import { Instagram } from "../SocialMedias";

const Nav = () => {
  const location = useLocation();

  const navAnim = {
    hidden: { y: -200 },
    show: { y: 0, transition: { duration: 1, ease: "easeOut" } },
  };

  const navToggle = (e) => {
    if (!document.querySelector(".burger").classList.contains("active")) {
      document.querySelector(".burger").classList.add("active");
      document.body.style.overflowY = "hidden";
      gsap.to(".line1", { rotate: "45", y: 5, duration: 0.5 });
      gsap.to(".line2", { width: "25px", rotate: "-45", y: -5, duration: 0.5 });
      gsap.to(".nav-menu", { clipPath: "circle(300vh at -10% -10%)", duration: 0.5 });
      gsap.to(".nav-menu", { pointerEvents: "all", duration: 0.1 });
    } else {
      document.querySelector(".burger").classList.remove("active");
      document.body.style.overflowY = "auto";
      gsap.to(".line1", { rotate: "0", y: 0, duration: 0.5 });
      gsap.to(".line2", { width: "18px", rotate: "0", y: 0, duration: 0.5 });
      gsap.to(".nav-menu", { clipPath: "circle(50px at -10% -10%)", duration: 1 });
      gsap.to(".nav-menu", { pointerEvents: "none", duration: 0.1 });
    }
  };

  return (
    <>
      <StyledNavbar></StyledNavbar>
      <StyledNav>
        <motion.div className="components" variants={navAnim} initial="hidden" animate="show">
          <div className="burger" onClick={navToggle}>
            <span className="line1"></span>
            <span className="line2"></span>
          </div>
          <a className={`link ${location.pathname === "/" ? "selected" : ""}`} href="/">
            <img src={logoPrincipal} alt="Logo" />
          </a>
        </motion.div>
      </StyledNav>
      <StyledNavMenu className="nav-menu">
        <div className="container-geral">
          <div className="links-container">
            <a className={`link ${location.pathname === "/" ? "selected" : ""}`} href="/">
              Início
            </a>
            <span className="line"></span>
            <a className={`link ${location.pathname === "/tecnologias" ? "selected" : ""}`} href="/tecnologias">
              Tecnologias
            </a>
            {/*<span className="line"></span>
             <a className={`link ${location.pathname === "/nosso-time" ? "selected" : ""}`} href="/nosso-time">
                                          Nosso time
                                    </a> */}
            <span className="line"></span>
            <a className={`link ${location.pathname === "/trabalhe-conosco" ? "selected" : ""}`} href="/trabalhe-conosco">
              Trabalhe conosco
            </a>
            <span className="line"></span>
          </div>

          <div className="social-media-container">
            <a href={Instagram} target="_blank" rel="noreferrer">
              Instagram
            </a>
            {/* <a href={Facebook} target="_blank" rel="noreferrer">
                                          Facebook
                                    </a>
                                    <a href={Twitter} target="_blank" rel="noreferrer">
                                          Twitter
                                    </a>
                                    <a href={Youtube} target="_blank" rel="noreferrer">
                                          Youtube
                                    </a>
                                    <a href={Linkedin} target="_blank" rel="noreferrer">
                                          Linkedin
                                    </a>
                                    <a href={Tiktok} target="_blank" rel="noreferrer">
                                          TikTok
                                    </a> */}
          </div>
        </div>
      </StyledNavMenu>
    </>
  );
};

const StyledNavbar = styled.div`
  min-width: 100vw;
  min-height: 64px;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;

  @media screen and (max-width: 568px) {
    min-height: 52px;
  }
`;

const StyledNav = styled(motion.nav)`
  min-width: 100vw;
  max-height: 64px;
  font-size: 1rem;
  font-family: "Kodchasan-Medium", sans-serif;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  a {
    display: flex;
    align-items: center;
  }
  .logo {
    z-index: 2;
  }
  .components {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 64px;
    width: 100%;
    position: relative;
    div {
      position: absolute;
      top: 50%;
      left: 5%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 1.5rem 1.5rem;
      span {
        min-height: 2px;
        background-color: white;
      }
      span:first-child {
        width: 25px;
        margin-bottom: 8px;
      }
      span:last-child {
        width: 18px;
      }
    }
    img {
      transition: all 0.2s ease;
    }
  }

  @media screen and (max-width: 568px) {
    max-height: 52px;
    .components {
      min-height: 52px;
    }
  }
`;

const StyledNavMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  @supports (backdrop-filter: saturate(180%) blur(20px)) {
    background-color: rgba(17, 17, 17, 0.72);
    backdrop-filter: saturate(180%) blur(20px);
  }
  z-index: 1;
  clip-path: circle(50px at -10% -10%);
  -webkit-clip-path: circle(50px at -10% -10%);
  z-index: 98;
  pointer-events: none;

  .container-geral {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: "Poppins-Regular", sans-serif;
    overflow-y: auto;
    background-color: #000;
    .links-container {
      width: 80vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .link {
        color: white;
        list-style: none;
        text-decoration: none;
        font-family: "Kodchasan-Medium", sans-serif;
        font-weight: 500;
        margin-bottom: 0.5rem;
        &:first-child {
          margin-top: 6rem;
        }
      }
      .selected {
        color: #397ee4;
      }
      .line {
        width: 100%;
        min-height: 1px;
        background-color: white;
        margin-bottom: 2.5rem;
        opacity: 0.2;
      }
    }

    .social-media-container {
      width: 80vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      a {
        text-decoration: none;
        padding: 1rem 1rem 1rem 0rem;
        font-weight: 300;
        color: white;
        font-size: 0.9rem;
      }
    }

    @media screen and (min-width: 768px) {
      .links-container {
        .link {
          font-size: 1.35rem;
          &:first-child {
            margin-top: 8rem;
          }
        }
        .line {
          opacity: 0.4;
        }
      }

      .social-media-container {
        a {
          font-size: 1.3rem;
        }
      }
    }

    @media screen and (max-width: 325px) {
      .links-container {
        .link {
          font-size: 0.9rem;
          &:first-child {
            margin-top: 5rem;
          }
        }

        .line {
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

export default Nav;
